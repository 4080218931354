<template>
  <v-theme-provider dark>
  <base-section
    id="vilkaviar"
    class="accent"
  >
  <base-section-heading
        color="transparent"
        title="Vilka vi är"
      >
      </base-section-heading>
    
    <v-responsive
      class="mx-auto"
      max-width="1350"
    >
      <v-container fluid>
        <v-row>
          <v-col
            cols="12"
            sm="4"
            md="6"
            align="center"
          >
              <base-img
              width=250
              :src="require(`@/assets/johan.png`)"
            />
            <base-info-card
              align="center"
              dark
              v-bind="cards[0]"
            >
            </base-info-card>
            <div class="text-center">
            <base-btn
              :tile="false"
              color="primary"
              :to="{ name: 'Johan' }"
              class="ma-2"
              rounded
            >
              Mer om Johan
            </base-btn>
          </div>

          </v-col>
          <v-col
            cols="12"
            sm="4"
            md="6"
            align="center"
          >
              <base-img
              width=250
              :src="require(`@/assets/kerstin.png`)"
            />
            <base-info-card
              align="center"
              dark
              v-bind="cards[1]"
            />
            <div class="text-center">
            <base-btn
              :tile="false"
              color="primary"
              :to="{ name: 'Kerstin' }"
              class="ma-2"
              rounded
            >
              Mer om Kerstin
            </base-btn>
          </div>
          </v-col>
        </v-row>
      </v-container>
    </v-responsive>
  </base-section>
</v-theme-provider>
</template>

<script>
  export default {
    name: 'SectionFeatures',

    data: () => ({
      cards: [
        {
          title: 'Johan Millving',
          text: 'Johan Millving är utbildad skådespelare vid Marieborgs Folkhögskola. Han har varit aktiv ett antal fria teatergrupper som producent, regissör och skådespelare sedan 2000.',
        },
        {
          title: 'Kerstin Bergström',
          text: 'Kerstin Bergström är utbildad skådespelare vid Neighborhood Playhouse Theater i New York. Hon har varit aktiv skådespelare i fria teatergrupper sedan 2004. Kerstin är manusförfattare samt har uppträtt med Stand-Up comedy på både engelska och svenska.',
        },
      ],
    }),
  }
</script>
